<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="10" lg="8" xl="6">
        <div class="d-flex justify-space-between align-center mb-4">
          <h2 class="mr-auto">Business Details</h2>

          <v-btn
            :to="{ name: 'new-tradie-details', params: { id: $attrs.id } }"
            :disabled="form.$busy || loadingInitValues"
            class="mr-6 px-5 back-button"
            text
          >
            <v-icon> {{ icons.back }} </v-icon>
            <span class="text-decoration-underline"> Go Back </span>
          </v-btn>

          <v-btn
            class="bg-primary-gradient primary next-button px-12"
            :disabled="form.$busy || loadingInitValues"
            :loading="form.$busy || loadingInitValues"
            @click="saveAndNext"
            depressed
            text
          >
            <span> Next </span>
            <v-icon> {{ icons.next }} </v-icon>
          </v-btn>
        </div>

        <div>
          <v-row>
            <v-col cols="6">
              <v-checkbox
                label="I am registered for GST?"
                v-model="form.is_apply_to_gst"
              />
            </v-col>

            <v-col cols="6" />

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.business_name"
                :disabled="form.$busy || loadingInitValues"
                label="Business Name"
                outlined
                flat
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-select
                v-model="form.business_area_id"
                label="Business Type"
                :loading="loadingInitValues"
                :items="businessAreas"
                :disabled="form.$busy || loadingInitValues"
                :error-messages="form.$getError('business_area_id')"
                item-value="id"
                item-text="business_area_name"
                @change="(value) => setValueToNull(value, 'business_area_id')"
                clearable
                outlined
                flat
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.business_contact_number"
                label="Business Contact Number"
                :error-messages="form.$getError('business_contact_number')"
                :disabled="form.$busy || loadingInitValues"
                outlined
                flat
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.website"
                label="Website (optional)"
                :disabled="form.$busy || loadingInitValues"
                :error-messages="form.$getError('website')"
                outlined
                flat
              />
            </v-col>
          </v-row>
        </div>

        <v-divider />

        <div class="mt-8">
          <h2 class="mb-5">Business Address</h2>

          <v-row>
            <v-col cols="12">
              <AddressLookup
                v-model="form.full_address"
                @change="selectedAddress"
                :error-messages="form.$getError('full_address')"
                :default-value="tradie.address"
                return-object
                clearable
                outlined
                flat
              />
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="form.floor_or_unit_number"
                label="Level/Floor/Unit/Flat"
                outlined
                flat
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.street_number"
                label="Street Number"
                readonly
                outlined
                flat
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.suburb"
                label="Suburb"
                readonly
                outlined
                flat
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.state"
                label="State"
                readonly
                outlined
                flat
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.postcode"
                label="Zip Code"
                readonly
                outlined
                flat
              />
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <ConfirmDialog ref="confirm" />
  </div>
</template>

<script>
import AddressLookup from '@/components/elements/AddressLookup'
import ConfirmDialog from '@/components/modals/ConfirmDialog'
// import BusinessArea from '@/models/BusinessArea'
import isDirtyFrom from './mixins/DirtyForm'
import Address from '@/models/Admin/Address'
import FormMixin from '@/utils/mixins/Form'
import Tradie from '@/models/Admin/Tradie'
import Form from '@/utils/form'

import { get, pick, merge, extend, isEmpty, isFunction } from 'lodash'
import { mdiArrowRight, mdiArrowLeft } from '@mdi/js'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'NewBusinessDetails',

  components: { AddressLookup, ConfirmDialog },

  mixins: [FormMixin, isDirtyFrom],

  data() {
    return {
      loadingNewTradie: true,
      loadingInitValues: true,
      form: new Form({
        id: null,
        website: null,
        business_name: null,
        is_apply_to_gst: false,
        business_area_id: null,
        business_contact_number: null,

        full_address: '',
        floor_or_unit_number: null,
        street_number: null,
        suburb: null,
        state: null,
        postcode: null,
        longitude: null,
        latitude: null,
      }),

      icons: {
        back: mdiArrowLeft,
        next: mdiArrowRight,
      },
    }
  },

  computed: {
    ...mapState({
      tradie: (state) => state.newTradie.tradie,
      businessAreas: (state) => state.businessArea.list,
    }),

    withNewTradie() {
      return !!this.$attrs.id
    },
  },

  created() {
    this.initValues()
  },

  methods: {
    ...mapActions({
      getTradieDetails: 'newTradie/getTradieDetails',
      getBusinessAreas: 'businessArea/getBusinessAreas',
    }),
    ...mapMutations('newTradie', ['setNewTradie']),

    async initValues() {
      await this.fetchBusinessTypes()

      if (this.withNewTradie && !this.tradie.tradie_id) {
        await this.getTradieDetails(this.$attrs.id)
      }

      this.setFormData()

      this.loadingInitValues = false
    },

    async fetchBusinessTypes() {
      this.loadingInitValues = true

      await this.getBusinessAreas({ page: 1, limit: 0 })
    },

    saveAndNext(redirect = null) {
      this.form.$clearErrors()
      this.form.$busy = true

      const tradie = new Tradie(this.form.$data())

      tradie
        .save()
        .then(async ({ data }) => {
          await this.setNewTradie(data)

          const { id } = data

          if (isFunction(redirect)) {
            return redirect()
          }

          this.$router.push({
            name: 'new-tradie-overview',
            params: { id },
          })
        })
        .catch(({ response }) => {
          this.form.$wait(() => {
            if (response && response.status === 422) {
              this.form.$setErrors(response.data.errors)
            }

            this.form.$busy = false
          })
        })
    },

    async setFormData() {
      const _tradie = pick(this.tradie, [
        'id',
        'website',
        'business_name',
        'is_apply_to_gst',
        'business_area_id',
        'business_contact_number',
      ])

      let _address = get(this.tradie, 'address', {})

      if (!isEmpty(_address)) {
        _address = pick(_address, [
          'full_address',
          'floor_or_unit_number',
          'street_number',
          'suburb',
          'state',
          'postcode',
          'longitude',
          'latitude',
        ])
      }

      const _data = merge(_tradie, _address)

      this.form = new Form(extend(this.form.$data(), _data))
    },

    selectedAddress(address) {
      this.form.full_address = get(address, 'full_address', null)

      this.form.street_number = get(address, 'street_number', null)
      this.form.postcode = get(address, 'postcode', null)
      this.form.suburb = get(address, 'suburb', null)
      this.form.state = get(address, 'state', null)

      this.form.longitude = get(address, 'longitude', null)
      this.form.latitude = get(address, 'latitude', null)

      if (!(address instanceof Address)) {
        return (this.form.floor_or_unit_number = null)
      }

      /* eslint-disable camelcase */
      const {
        flat_unit_type,
        flat_unit_number,
        floor_level_type,
        floor_level_number,
      } = address

      this.form.floor_or_unit_number = [
        flat_unit_type,
        flat_unit_number,
        floor_level_type,
        floor_level_number,
      ].join(' ')
    },
  },
}
</script>

import { isNil } from 'lodash'

export default {
  data() {
    return {}
  },

  methods: {
    setValueToNull(value, param, _default = null) {
      if (isNil(value)) {
        this.form[param] = _default
      }
    },
  },
}

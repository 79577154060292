import { get, has } from 'lodash'

export default {
  data() {
    return {
      perisistDialog: false,
    }
  },

  computed: {
    dirtyForm() {
      return this.form.$isDirty()
    },
  },

  async beforeRouteLeave(to, from, next) {
    const isNewTradieRoute = get(to.meta, 'newTradie', false)

    let confirmMessage = 'Do you want to keep changes on this page?'

    if (this.dirtyForm || this.perisistDialog) {
      if (isNewTradieRoute) {
        return next()
      }

      if (this.$attrs.id) {
        confirmMessage = `Tradie details are saved as draft. <br><br> ${confirmMessage}`
      }

      const hasDialog = has(this.$refs, 'confirm')

      if (hasDialog) {
        const confirm = await this.$refs.confirm.open(
          'Save Changes?',
          confirmMessage
        )

        return confirm ? this.saveAndNext(next) : false
      }

      return next()
    }

    next()
  },
}

<template>
  <v-autocomplete
    v-bind="$attrs"
    v-model="address"
    item-text="full_address"
    item-value="full_address"
    :label="label"
    :items="addresses"
    :loading="loading"
    :readonly="readonly"
    :search-input.sync="search"
    @change="selectedAddress"
    @keyup="searchAddress"
    no-filter
  >
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title v-text="data.item.full_address" />
        <v-list-item-subtitle
          class="text-no-wrap"
          v-if="withDescription"
          style="width: 20px"
        >
          {{ data.item.full_address }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import Address from '@/models/Admin/Address'

import { has, flatMap, debounce } from 'lodash'
import { mdiInformation } from '@mdi/js'

export default {
  name: 'AddressLookup',

  props: {
    value: {
      type: [String, Number, Object],
    },

    defaultValue: {
      type: [Object, Array],
    },

    label: {
      type: String,
      default: 'Search Address',
    },

    errors: {
      type: [Object, Array],
    },

    withDescription: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      search: null,
      addresses: [],
      loading: false,
      address: this.value,
      icons: {
        info: mdiInformation,
      },
    }
  },

  computed: {
    searchInputErrors() {
      return Array.isArray(this.errors) && this.exercise
        ? this.errors
        : flatMap(this.errors)
    },

    hasErrors() {
      return !!this.searchInputErrors.length
    },
  },

  created() {
    this.setDefaultValue()
  },

  methods: {
    searchAddress: debounce(function () {
      this.loading = true

      Address.where('full_address', this.search || this.address)
        .get()
        .then(({ data }) => {
          this.addresses = data
        })
        .catch((err) => {
          return err
        })
        .finally(() => {
          this.loading = false
        })
    }, 250),

    selectedAddress(address) {
      if (address) {
        this.$emit('input', address.full_address)
        this.$emit('change', address)
      }

      if (!address && has(this.$attrs, 'clearable')) {
        this.$emit('input', null)
        this.$emit('change', null)
      }
    },

    setDefaultValue() {
      this.address = this.value

      if (this.address === '') return

      if (Array.isArray(this.defaultValue)) {
        this.addresses.push(...this.defaultValue)
      } else {
        this.addresses.push(this.defaultValue)
      }
    },
  },

  watch: {
    defaultValue() {
      this.setDefaultValue()
    },

    value(value) {
      this.address = value
    },
  },
}
</script>

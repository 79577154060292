<template>
  <v-dialog
    v-model="dialog"
    :persistent="persistent"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text v-show="!!message" class="pa-4 body-1" v-html="message" />

      <v-card-actions class="pt-0">
        <v-spacer />

        <v-btn v-if="!hasCustomCancel" color="grey" text @click.native="cancel">
          <span v-if="cancelText"> {{ cancelText }} </span>
          <span v-else> {{ options.cancelText }} </span>
        </v-btn>

        <slot name="cancel"></slot>

        <v-btn :color="`${options.color} darken-1`" text @click.native="agree">
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',

  props: {
    persistent: {
      type: Boolean,
      default: false,
    },

    cancelText: {
      type: String,
    },

    color: {
      type: String,
      default: 'primary',
    },
  },

  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,

      options: {
        color: this.color,
        width: 300,
        zIndex: 200,
        cancelText: 'Cancel',
      },
    }
  },

  computed: {
    hasCustomCancel() {
      return !!this.$slots.cancel
    },
  },

  methods: {
    open(title, message, options) {
      this.dialog = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      if (typeof this.resolve === 'function') {
        this.resolve(true)
      }

      this.$emit('confirmed', true)

      this.dialog = false
    },
    cancel() {
      if (typeof this.resolve === 'function') {
        this.resolve(false)
      }

      this.$emit('confirmed', false)

      this.dialog = false
    },
  },
}
</script>
